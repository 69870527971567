import React from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/Layouts/mainPage"
import { NewHeader } from "../components/Headers/NewHeader"
import { Banner } from "../components/Banners/Dacha"
import { CardWithMaxBenefits } from "../components/CardWithMaxBenefits"
import { FormPKW } from "../components/FormPKW"
import { NewCalculator } from "../components/NewCalculator/NewCalculator"
import { Partners } from "../components/PartnersNew"
import { NewFooter } from "../components/NewFooter"

import { getPageData } from "../helpers/getPageData"

import { PageData } from "../interfaces/pageProps"

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)

  return (
    <Layout noHeader noFooter noIndex={noIndex}>
      <NewHeader />
      <Banner orderNum="1" />
      <CardWithMaxBenefits orderNum="2" />
      <FormPKW dataLayerName="shortPersonalForm" orderNum="3" />
      <NewCalculator darkColor orderNum="4" />
      <Partners darkColor orderNum="5" />
      <NewFooter ligal={ligal} orderNum="6" />
    </Layout>
  )
}
export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/pkw-dacha/") {
        url
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
